import { useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router";
import { fetchGetScoreboard } from '../ExamsApi';
import { selectUserData } from '../../../features/User/userSlice';
import { useSelector } from "react-redux";
import debounce from 'lodash.debounce'

import '../assets/Exam.css'
import SecureImage from '../../../components/_partials/SecuredImage';
import { Avatar } from '../../../components/_partials/Avatar';
import { API_URL } from '../../../config/constants';

import score_img from '../assets/images/score.svg'
import up_img from '../assets/images/up.svg'
import first_img from '../assets/images/first.svg'
import second_img from '../assets/images/second.svg'
import third_img from '../assets/images/third.svg'
import Anchor from '../../../components/Anchor';
import { selectTranslations } from '../../../services/i18n/i18nSlice';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

const position_image_mapping = {
    "1": first_img,
    "2": second_img,
    "3": third_img
}

export default function Scoreboard(props) {

    const t = useSelector(selectTranslations)


    const RESULTS_PER_LOAD = 20

    const location = useLocation()
    const [scoreboardData, setScoreboardData] = useState([])
    const [scoreboardNavigationData, setScoreboardNavigationData] = useState({
        hasMoreTop: true,
        hasMoreBottom: true,
        currentTop: 0
    })
    const [scroll, setScroll] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const userData = useSelector(selectUserData)

    const topRef = useRef()
    const ref = useRef()
    const containerRef = useRef()

    const loadScoreboard = async (e) => {
        const response = await fetchGetScoreboard({exam_id: props.examId, limit:RESULTS_PER_LOAD})
        if(response.success){
            setScoreboardData([...scoreboardData, ...response?.data?.scoreboard])
            setScoreboardNavigationData({...scoreboardNavigationData, hasMoreBottom: response.data?.next_data?.bottom?.cursor !== null, hasMoreTop: response.data?.next_data?.top?.cursor !== null, currentTop: response.data?.scoreboard[0].position, ...response.data?.next_data})
            setTimeout(()=>{
                if(ref?.current)
                    ref?.current?.scrollIntoView({
                behavior: 'smooth',
            });
            },500)
        } else {
            toast.error(t.scoreboard.not_available)
        }
        
        
    }

    const loadMoreScoreboardTop = async (e) => {
        console.log('load top fetch');
        setIsLoading(true)
        // Salva la posizione corrente dello scroll
        const currentScrollPosition = containerRef?.current?.scrollTop;
        const currentScrollHeight = containerRef?.current?.scrollHeight;

        const response = await fetchGetScoreboard({exam_id: props.examId, limit:RESULTS_PER_LOAD, ...scoreboardNavigationData?.top})
        if(response.success){
            const _new_data = response?.data?.scoreboard?.reverse()
            setScoreboardData(prevData => [..._new_data, ...prevData])
            setScoreboardNavigationData({...scoreboardNavigationData, hasMoreTop: _new_data?.length > 0, currentTop: _new_data[0]?.position, ...response.data?.next_data})
            
            // Una volta che i nuovi post sono stati aggiunti, ricalcola la posizione dello scroll
            setTimeout(() => {
                const newScrollHeight = containerRef?.current?.scrollHeight;
                containerRef.current.scrollTop = newScrollHeight - currentScrollHeight + currentScrollPosition;
            }, 0);
            setIsLoading(false)
        }
    }

    const loadMoreScoreboardBottom = async (e) => {
        console.log('load bottom fetch');
        setIsLoading(true)
        const response = await fetchGetScoreboard({exam_id: props.examId, limit:RESULTS_PER_LOAD, ...scoreboardNavigationData?.bottom})
        if(response.success){
            setScoreboardData([...scoreboardData, ...response?.data?.scoreboard])
            setScoreboardNavigationData({...scoreboardNavigationData, hasMoreBottom: response.data?.scoreboard?.length > 0, ...response.data?.next_data})
            setIsLoading(false)
        }
    }

    const scrollToTop = async () => {
        if(scoreboardData){
            console.log('load first fetch');
            setIsLoading(true)
            // Salva la posizione corrente dello scroll
            const currentScrollPosition = containerRef?.current?.scrollTop;
            const currentScrollHeight = containerRef?.current?.scrollHeight;

            const response = await fetchGetScoreboard({exam_id: props.examId, limit:RESULTS_PER_LOAD, sorting:"descending"})
            if(response.success){
                const _new_data = response?.data?.scoreboard
                setScoreboardData(_new_data)
                setScoreboardNavigationData({...scoreboardNavigationData, hasMoreTop: false, hasMoreBottom: response.data?.next_data?.bottom?.cursor !== null, currentTop: _new_data[0]?.position, ...response.data?.next_data})
                
                setTimeout(()=>{
                    topRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                    });
                },100)
                setIsLoading(false)
            }
        }
    }

    const _handleScroll = (e) => {
        const obj = e.target
        if( scroll < obj.scrollTop)
            if( obj.scrollTop >= (obj.scrollHeight - obj.offsetHeight - 50)){
                console.log('load bottom');
                if(scoreboardNavigationData?.hasMoreBottom)
                    loadMoreScoreboardBottom()
            }
                
        if( scroll > obj.scrollTop)
            if( obj.scrollTop < 100){
                console.log('load top');
                if(scoreboardNavigationData?.hasMoreTop)
                    loadMoreScoreboardTop()
            }
        setScroll(obj.scrollTop)
    }

    const handleScroll = debounce(_handleScroll,200)

    useEffect(()=>{ 
        console.log(props.examId);
        
        if(props.examId)
            loadScoreboard()
    },[])



    return(
        <>
        <span className="text-center mx-auto mt-2 pointer text-primary d-block w-100" onClick={scrollToTop}><u>{t.scoreboard.go_to_top}</u> <img src={up_img}/> </span>
        <div className='text-center' style={{height: props.height ? props.height : "85%", overflow:'hidden'}} >
            <div style={{height:'100%',overflow:'scroll'}} className='scoreboard mt-2' onScroll={handleScroll} ref={containerRef}>
                {isLoading && <Spinner key={'top_loader'} />}
                {(props.examId && scoreboardData) &&
                <>
                    {scoreboardData?.map((s,i)=>{
                        return(
                            <div key={i} className={`scoreboard_card ${s.user_data?.user_id === userData.user_id ? 'active' : ''}`} ref={s.user_data?.user_id === userData.user_id ? ref : (s.position === scoreboardNavigationData.currentTop ? topRef : null)}>
                                <div className='scoreboard_card__content'>
                                    {s.position === 1    || s.position === 2 || s.position === 3 ?
                                        <span className='scoreboard_card__position'>
                                            <img src={position_image_mapping[s.position]} />
                                        </span>
                                    : <span className='scoreboard_card__position'>{s.position}°</span>}
                                    <div className="scoreboard_card__image ms-2">
                                        {s.user_data?.profile_image_url ? 
                                            <SecureImage imageUrl={`${API_URL}${s.user_data?.profile_image_url}`} />
                                        : <Avatar avatar_id={s.user_data?.profile_avatar_id || 0} />
                                        }
                                    </div>
                                    <span className='scoreboard_card__name ms-3'>{s.user_data?.nickname ? s.user_data?.nickname : s.user_data?.firstname}</span>
                                </div>
                                <div className='scoreboard_card__score'>
                                    <span className=''>{s.total_score} pt</span>
                                    <img className="ms-2" src={score_img} />
                                </div>
                                
                            </div>
                        )
                    })}
                </>}
                {(isLoading && scoreboardData?.length > 0 ) && <Spinner key={'bottom_loader'} />}
            </div>
        </div>
        </>
    )
}