import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCustomizationsSetting, fetchOfaSetting } from './appInfoApi';
//import CallServer from '../../api/CallServer';
import { GetFromLocalStorage, SaveToLocalStorage } from '../../utils/utils';
import { BASE_URL, API_URL, TENANT, OFA_TENANT } from '../../config/constants';

const initialState = {
  base_url: BASE_URL,
  api_url: API_URL,
  error: null,
  is_ofa: OFA_TENANT.includes(TENANT),
  personalizations: GetFromLocalStorage('customization_settings') || null
};

export const loadAppCustomizationsData = createAsyncThunk(
  'appInfo/loadStaticData',
  async () => {
      const response = await fetchCustomizationsSetting();
      if (response?.success) {
          const response_ofa = await fetchOfaSetting();
          if(response.success)
            SaveToLocalStorage("customization_settings",{...response.data, ofa_settings: response_ofa?.data});
          else
            SaveToLocalStorage("customization_settings",{...response.data});
          document.title = response.data?.app_name
          return {...response.data, ofa_settings: response_ofa?.data || null}
      } else {
        return response
      }
  }
);

export const appInfoSlice = createSlice({
  name: "appInfo",
  initialState,
  reducers: {
    setInfoData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.personalizations = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAppCustomizationsData.fulfilled, (state, action) => {
        if(!action?.payload?.error)
          state.personalizations = action?.payload;
        else{
          state.error = action?.payload?.error;
        }  
          
      });
  },
});
export default appInfoSlice.reducer;

export const selectAppInfo = (state) => state.appInfo;
export const selectBaseUrl = (state) => state.appInfo.base_url;
export const selectApiUrl = (state) => state.appInfo.api_url;
export const selectAppPersonalizations = (state) => state.appInfo.personalizations;