import { useState } from "react";
import { isMobile } from "react-device-detect";
import Sheet from 'react-modal-sheet';

import computer_img from '../../assets/images/computer.svg'
import computer_pc_img from '../../assets/images/computer-pc.svg'
import './DesktopSheet.css'
import { TitleH2 } from "../TitleH2";
import { Text } from "../Text";
import { useSelector } from "react-redux";
import { selectUserData } from "../../features/User/userSlice";
import { Button } from "../Button";
import { toast } from "react-toastify";
import { selectAppInfo } from "../../services/utils/appInfoSlice";
import { TENANT } from "../../config/constants";

export default function DesktopSheet () {

    const [isOpen,setIsOpen] = useState(false)
    const userInfo = useSelector(selectUserData)

    const appInfo = useSelector(selectAppInfo)

    const ofa_url =  appInfo.is_ofa ? process.env.REACT_APP_OFA_SAPIENZA_URL : `${window.location.host}/${TENANT}` 

    const copyUrl = () => {
        let obj = { email: userInfo.email }
        const url = new URL(`${ofa_url}/ofa-sapienza/login/`);
        url.search = new URLSearchParams(obj);
        navigator.clipboard.writeText(url);
        toast.success("Indirizzo copiato")
        setIsOpen(false)
    }

    return (
        <>
            {isMobile &&
                <div className="desktop_reminder" onClick={()=>setIsOpen(true)}>
                    <img src={computer_img} alt="" />
                </div>
            }
            {isMobile &&
                <Sheet isOpen={isOpen} onClose={()=>{setIsOpen(false)}} detent='content-height'>
                    <Sheet.Container>
                        <Sheet.Header />
                        <Sheet.Content className="text-center pb-3 px-3">
                            <img src={computer_pc_img} className="mx-auto mt-3" style={{width:'52px'}} alt=""/>
                            <TitleH2 className="mt-2">Accedi da computer con l’e-mail di iscrizione</TitleH2>
                            <Text className="mt-2">Utilizza l’e-mail <strong style={{color:'#436094'}}>{userInfo.email}</strong> per accedere dal tuo computer.<br /> Collegati all’indirizzo <i>{ofa_url}</i></Text>
                            <Button onClick={copyUrl} color="secondary" className="mb-5 mt-3 w-100 mx-auto">Copia l'indirizzo</Button>
                        </Sheet.Content>
                    </Sheet.Container>
                        <Sheet.Backdrop onTap={()=>{setIsOpen(false)}}/>
                </Sheet>
            }
        </>
    )
}