import React, { useState, useEffect } from 'react';
import BottomSheet from '../../components/_modals/BottomSheet';
import { useSelector } from 'react-redux';
import { selectTranslations } from '../../services/i18n/i18nSlice';
import { TitleH2 } from '../../components/TitleH2';
import { Text } from '../../components/Text';
import { isMobile } from 'react-device-detect';
import { Button } from '../../components/Button';
import safari_share_img from '../../assets/images/safari_share.svg'
import download_img from '../../assets/images/download.svg'
import { toast } from 'react-toastify';


const InstallPromptModal = () => {
  const [isStandalone, setIsStandalone] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);

  const FIVE_DAYS_IN_MS = 5 * 24 * 60 * 60 * 1000;

  const t = useSelector(selectTranslations)
  
  useEffect(() => {
    // Verifica se la PWA è già installata
    const standaloneCheck = window.matchMedia('(display-mode: standalone)').matches;
    setIsStandalone(standaloneCheck);

    // Funzione per rilevare se l'utente usa Safari
    const isSafari = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /safari/.test(userAgent) && !/chrome/.test(userAgent);
    };

    setIsSafariBrowser(isSafari());

    if (!standaloneCheck) {
      // Controlla se l'utente ha già visto la modale nei 5 giorni precedenti
      const lastSeenDate = localStorage.getItem('pwaPromptLastSeen');
      const now = new Date().getTime();

      if (!lastSeenDate || now - lastSeenDate > FIVE_DAYS_IN_MS) {
        // Se sono passati più di 5 giorni o se non è stata mai vista prima

        if (isSafari()) {
          // Safari non supporta il beforeinstallprompt, quindi mostriamo comunque la modale
          setShowModal(true);
        } else {
          // Ascolta l'evento beforeinstallprompt per i browser che lo supportano
          const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e); // Salva il prompt per poterlo mostrare più tardi
            setShowModal(true);   // Mostra la modale
          };

          window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

          return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
          };
        }
      }
    }
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Mostra il prompt di installazione
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
            toast.success(t.pwa_modal.app_installed)
            console.log('PWA installata con successo');
            const now = new Date().getTime();
            localStorage.setItem('pwaPromptLastSeen', now);
            setShowModal(false);
        } else {
            console.log('PWA non installata');
        }
        setDeferredPrompt(null); // Reset del prompt
      });
    }
  };

  const handleCloseModal = () => {
    const now = new Date().getTime();
    localStorage.setItem('pwaPromptLastSeen', now);
    setShowModal(false);
  };

  return (
    <>
      {!isStandalone && showModal && (
        <BottomSheet isOpen={showModal} onClose={handleCloseModal} showExit={false}>
            <div className="lassName='mb-5 mb-md-3 mt-3 px-4 text-center'">
                <img src={download_img} className='mx-auto d-block mb-3' style={{minWidth:'45px'}}/>
                {isSafariBrowser ? (
                <>
                    <TitleH2 className='text-center'>{t.pwa_modal.title}</TitleH2>
                    <Text className='text-center'>{t.pwa_modal.text_safari_0} <img src={safari_share_img} /> {isMobile ? t.pwa_modal.text_safari_1 : t.pwa_modal.text_safari_2}</Text>
                    <Button className='mx-auto w-100 mb-5 mt-4 mb-md-0' onClick={handleCloseModal}>{t.pwa_modal.ok_safari}</Button>
                </>
                ) : (
                <>
                    <TitleH2 className='text-center'>{t.pwa_modal.title}</TitleH2>
                    <Text className='text-center'>{t.pwa_modal.text_generic}</Text>
                    <Button className='mx-auto w-100' onClick={handleInstallClick}>{t.pwa_modal.download}</Button>
                    <Button className='mx-auto w-100 mt-3 mb-5 mb-md-0' type='button' color='secondary' onClick={handleCloseModal}>{t.buttons.close}</Button>
                </>
                )}
            </div>
        </BottomSheet>
      )}
    </>
  );
};

export default InstallPromptModal;
