import { useEffect, useRef, useState } from 'react';

import { useLocation, useNavigate, redirect, useParams } from "react-router";
import { fetchGetScoreboard } from './ExamsApi';
import { selectUserData } from '../../features/User/userSlice';
import { useDispatch, useSelector } from "react-redux";

import './assets/Exam.css'
import SecureImage from '../../components/_partials/SecuredImage';
import { Avatar } from '../../components/_partials/Avatar';
import { API_URL } from '../../config/constants';

import arrow_img from '../../assets/images/arrow-back.svg'
import Scoreboard from './_components/Scoreboard';
import { TitleH1 } from '../../components/TitleH1';
import { selectTranslations } from '../../services/i18n/i18nSlice';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { setNavbarHide, setNavbarHideMobile } from '../../services/global/globalSlice';


export default function ExamScoreboard(props) {

    const location = useLocation()
    const t = useSelector(selectTranslations)

    const dispatch = useDispatch()
   
    const { exam_id } = useParams()

    useEffect(() => {
        dispatch(setNavbarHideMobile(true))
        dispatch(setNavbarHide(false))
    }, [exam_id])

    return(
        <section style={{height:'85%'}}>
            <div className='section_header'>
                <Link to={'/exams/history'}><img src={arrow_img} className='pointer' alt=""/></Link>
            </div>
            <TitleH1 className='text-center mt-4 mt-md-5 pt-4'>{t.scoreboard.title}</TitleH1>
            <Scoreboard height={'80%'} examId={exam_id} />
        </section>
    )
}