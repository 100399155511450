import { useSelector } from "react-redux";
import { selectAppPersonalizations } from "../services/utils/appInfoSlice";
import styled from 'styled-components'


const DefaultButton = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.$bgColor};
  color: ${props => props.$color};
  font-size: 18px;
  font-weight: 700;
  padding: 8px 32px;
  border: ${props => props.$borderSize}px solid;
  border-color: ${props => props.$borderColor};
  border-radius: ${props => props.$borderRadius}px;
  max-width: 360px;
  display: block;
`;


export const Button = ({ type='button', color='primary', className='', style={}, children, disabled=false, onClick = null }) => {

    const personalizations = useSelector(selectAppPersonalizations);

    const colors = {
        primary: personalizations?.primary_button?.text?.color ? personalizations?.primary_button?.text?.color : '#FFFDFC',
        secondary: personalizations?.secondary_button?.text?.color ? personalizations?.secondary_button?.text?.color : 'var(--primary-color)'
    }

    const bgColors = {
        primary: personalizations?.primary_button?.background_color ? personalizations?.primary_button?.background_color : 'var(--primary-color)',
        secondary: personalizations?.secondary_button?.background_color ? personalizations?.secondary_button?.background_color : '#FFFDFC'
    }

    const borderColors = {
        primary: personalizations?.primary_button?.border?.color ? personalizations?.primary_button?.border?.color : 'var(--primary-color)',
        secondary: personalizations?.secondary_button?.border?.color ? personalizations?.secondary_button?.border?.color: 'var(--primary-color)'
    }

    const borderSize = {
        primary: personalizations?.primary_button?.border?.depth ? personalizations?.primary_button?.border?.depth : '2',
        secondary: personalizations?.secondary_button?.border?.depth ? personalizations?.secondary_button?.border?.depth: '2'
    }

    const borderRadius = {
        primary: personalizations?.primary_button?.corner_radius ? personalizations?.primary_button?.corner_radius : '16',
        secondary: personalizations?.secondary_button?.corner_radiush ? personalizations?.secondary_button?.corner_radius: '16'
    }


    return (
        <DefaultButton style={style} type={type} disabled={disabled} className={`${color} ${className}`} $level={color} onClick={onClick} $color={colors[color]} $bgColor={bgColors[color]} $borderColor={borderColors[color]} $borderSize={borderSize[color]} $borderRadius={borderRadius[color]}>
            {children}
        </DefaultButton>
    )
}

