import {Text} from "../Text";
import Modal from 'react-bootstrap/Modal';
import {Button} from "../Button";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../services/i18n/i18nSlice";
import { useEffect, useState } from "react";
import { selectAppInfo, selectAppPersonalizations } from "../../services/utils/appInfoSlice";

import img_1 from '../../assets/images/ofa/formazione.svg'
import img_2 from '../../assets/images/ofa/computer-pc.svg'
import img_3 from '../../assets/images/ofa/verifica.svg'
import img_close from '../../assets/images/close.svg'
import { TitleH1 } from "../TitleH1";
import { GetFromLocalStorage, SaveToLocalStorage } from "../../utils/utils";
import { API_URL, TENANT } from "../../config/constants";
import { isMobile } from "react-device-detect";

export default function OnboardingModal () {

    
    const t = useSelector(selectTranslations)

    const appPersonalizations = useSelector(selectAppPersonalizations)
    const appInfo = useSelector(selectAppInfo)

    const steps = isMobile ? [
    {
        icon: `${API_URL}${appPersonalizations?.logo_url}`,
        title: t.welcome_onboarding.step_0_title,
        text: t.welcome_onboarding.step_0_text
    },
    {
        icon: img_1,
        title: t.welcome_onboarding.step_1_title,
        text: t.welcome_onboarding.step_1_text
    },
    {
        icon: img_2,
        title: t.welcome_onboarding.step_2_title,
        text: t.welcome_onboarding.step_2_text
    },
    {
        icon: img_3,
        title: t.welcome_onboarding.step_3_title,
        text: t.welcome_onboarding.step_3_text
    },
] : [
    {
        icon: `${API_URL}${appPersonalizations?.logo_url}`,
        title: t.welcome_onboarding.step_0_title,
        text: t.welcome_onboarding.step_0_text
    },
    {
        icon: img_1,
        title: t.welcome_onboarding.step_1_title,
        text: t.welcome_onboarding.step_1_text
    },
    {
        icon: img_3,
        title: t.welcome_onboarding.step_3_title,
        text: t.welcome_onboarding.step_3_text
    },
]

    const [step, setStep] = useState(0)
    const [show, setShow] = useState(false)

    

    const setNextStep = (_,override = false) => {
        if(step === steps?.length-1 || override){
            SaveToLocalStorage(`${TENANT}_app_onboarding`,true)
            setShow(false)
        } else
            setStep(step+1)
    }
    
    useEffect(()=>{
        if(!appInfo?.is_ofa && !GetFromLocalStorage(`${TENANT}_app_onboarding`))
            setShow(true)
    },[appInfo?.is_ofa])

    return (
        <>
            <Modal
                show={show && appPersonalizations?.logo_url}
                keyboard={false}
                centered
                size="md"
            >
                <Modal.Body className="text-center" style={{minHeight:'32vh'}}>
                    <div className="d-flex justify-content-between mb-2">
                        <span>{step+1} {t.ofa.of} {steps.length}</span>
                        <img src={img_close} alt="" className="pointer" onClick={()=>setNextStep(true,true)} />
                    </div>
                    <img src={steps[step]?.icon} style={{maxWidth:'50%', maxHeight:'40px'}} alt="" />
                    <TitleH1 className="mt-3 px-2">{steps[step]?.title}</TitleH1>
                    <Text className="mt-2 px-2">{steps[step]?.text}</Text>
                    <div className="d-flex justify-content-between mt-4">
                        <Button color="secondary" className="w-50 me-2" onClick={()=>setStep(step-1)} disabled={step===0}>
                            {t.ofa.back}
                        </Button>
                        <Button className="w-50" onClick={setNextStep}>
                            {step !== steps?.length-1 ? t.ofa.next : t.ofa.exit}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}