import { useDispatch, useSelector } from "react-redux";
import { selectTranslations } from "../../services/i18n/i18nSlice";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { setLoading, setNavbarHide, setNavbarHideMobile } from "../../services/global/globalSlice";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import Accordion from 'react-bootstrap/Accordion';

import arrow_img from '../../assets/images/arrow-back.svg'
import correct_img from '../../assets/images/questions/correct.svg'
import missing_img from '../../assets/images/questions/missing.svg'
import wrong_img from '../../assets/images/questions/wrong.svg'
import ofa_img from '../../assets/images/ofa/question.svg'
import structure_img from './assets/images/structure.svg'
import point_img from './assets/images/score.svg'
import score_img from './assets/images/scoring.svg'

import { TitleH1 } from "../../components/TitleH1";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { fetchGetExam, fetchStartExam } from "./ExamsApi";
import { showError } from "../../features/Error/errorSlice";
import { setCurrentExam } from "../../features/Exam/examSlice";
import ConfirmModal from "../../components/_modals/ConfirmModal";
import { selectAppInfo } from "../../services/utils/appInfoSlice";

export default function ExamPreviewScreen(){

    const [examData, setExamData] = useState(null)
    const [examStarted, setExamStarted] = useState(false)
    const t = useSelector(selectTranslations)
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const appInfo = useSelector(selectAppInfo)

    const [confirmModal, setConfirmModal] = useState({
        title: "",
        text: "",
        onClose: null,
        onConfirm: null,
        confirmCta: "",
        closeCta: "",
        icon: ofa_img
    })

    const {exam_id} = useParams()

    const renderTotalTime = () => {
        let total = 0
        examData?.modules?.forEach(m=> {
            total += m.time
        });
        return total
    }
    
    const closeConfirmModal = () => {
        setConfirmModal({
            ...confirmModal,
            show: false
        })
    }

    const startLinearExam = async (_,forceStart = false) => {
        if(examStarted) {
            dispatch(setLoading(true))
            dispatch(setCurrentExam({exam_id: exam_id}))
            navigate('/exams/perform/',{replace:true, state:examData})
        } else {
            if (appInfo.is_ofa && !forceStart){
                setConfirmModal({
                    ...confirmModal,
                    title: t.ofa.exam.start,
                    text: t.ofa.exam.start_text,
                    confirmCta: t.ofa.exam.start_button,
                    onConfirm: () => startLinearExam(true,true),
                    onClose: () => {closeConfirmModal()},
                    show: true
                })
            } else {
                dispatch(setLoading(true))
                const check_started = await fetchGetExam({exam_id:exam_id})
                if(!check_started.success && check_started.error === "User exam not started"){
                    const response = await fetchStartExam({exam_id: examData?.exam_id})
                    if(response.success){
                        dispatch(setCurrentExam({exam_id: exam_id}))
                        navigate('/exams/perform/',{replace:true, state:response.data})
                    } else {
                        dispatch(showError({
                            title: t.exam.errors.start,
                            text: t.exam.errors.start_text,
                            redirect: "/exams",
                            cta_text: t.exam.errors.go_back
                        }))
                    }
                } else {
                    dispatch(setLoading(true))
                    dispatch(setCurrentExam({exam_id: exam_id}))
                    navigate('/exams/perform/',{replace:true, state:examData})
                }
                
            }
            
        }
        
    }

    const startModuleExam = async () => {
        dispatch(setLoading(true))
        if(examStarted) {
            dispatch(setCurrentExam({exam_id: exam_id}))
            navigate('/exams/perform/',{replace:true, state:examData})
        } else {
            const check_started = await fetchGetExam({exam_id:exam_id})
            if(!check_started.success && check_started.error === "User exam not started"){
                const response = await fetchStartExam({exam_id: exam_id})
                if(response.success){
                    dispatch(setCurrentExam({exam_id: exam_id}))
                    navigate('/exams/start_module/',{replace:true, state:response.data})
                } else {
                    dispatch(showError({
                        title: t.exam.errors.start,
                        text: t.exam.errors.start_text,
                        redirect: "/exams",
                        cta_text: t.exam.errors.go_back
                    }))
                }
            } else {
                dispatch(setCurrentExam({exam_id: exam_id}))
                navigate('/exams/perform/',{replace:true, state:examData})
            }
        }
    }

    const loadExamData = async (exam_id) => {
        const response = await fetchGetExam({exam_id:exam_id})
        if(response.success){
            let _examData = response.data?.exam
            setExamData(_examData)
            setExamStarted(response.data?.start_date ? true : false)
            dispatch(setLoading(false))
        } else {
            dispatch(showError({
                title: t.exam.errors.start,
                text: t.exam.errors.start_text,
                redirect: "/exams",
                cta_text: t.exam.errors.go_back
            }))
        }
    }
    const checkExamStart = async (exam_id) => {
        const response = await fetchGetExam({exam_id:exam_id})
        if(response.success){
            setExamStarted(response.data?.start_date ? true : false)
            dispatch(setLoading(false))
        } else {
            if(response.error !== "User exam not started")
                dispatch(showError({
                    title: t.exam.errors.start,
                    text: t.exam.errors.start_text,
                    redirect: "/exams",
                    cta_text: t.exam.errors.go_back
                }))
        }
    }

    useEffect(()=>{
        dispatch(setLoading(true))
        if(location?.state?.exam?.is_started)
            loadExamData(location.state?.exam?.exam_id)
        else if(location.state?.exam){
            setExamData(location.state.exam) 
            checkExamStart(location.state.exam?.exam_id) 
        } else
            navigate('/exams',{replace:true})
        dispatch(setLoading(false))
        // eslint-disable-next-line
    },[location])

    useEffect(()=>{
        dispatch(setNavbarHide(false))
        dispatch(setNavbarHideMobile(true))
        // eslint-disable-next-line
    },[examData?.exam_id, exam_id])

    return (
        <section id="examPreviewScreen">
            <div className='section_header'>
                <Link to={'/exams'}><img src={arrow_img} className='pointer' alt=""/></Link>
            </div>
            {examData ?
            <>
                <div className="pt-5">
                    <TitleH1 className="text-center">{examData?.title}</TitleH1>
                    <Text className="text-center">{examData?.description}</Text>
                    <Accordion defaultActiveKey={appInfo.is_ofa ? 'ofa' : '0'} style={{maxWidth:'500px'}} className="mx-auto">
                        {
                            appInfo.is_ofa &&
                            <Accordion.Item eventKey="ofa" >
                                <Accordion.Header><img src={point_img} alt="" className="me-2"/> {t.ofa.exam.score}</Accordion.Header>
                                <Accordion.Body>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Text className="mb-0">{t.ofa.exam.minimum_score}</Text>
                                        <span><strong>18 punti</strong></span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        }
                    <Accordion.Item eventKey="0" >
                        <Accordion.Header><img src={structure_img} alt="" className="me-2"/> {t.exam.structure}</Accordion.Header>
                        <Accordion.Body>
                            <div className="d-flex justify-content-between align-items-center">
                                <Text className="mb-0">{t.exam.duration}</Text>
                                <span><strong>{examData.time ? examData.time : renderTotalTime()} min</strong></span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <Text className="mb-0">{t.exam.questions}</Text>
                                <span><strong>{examData?.questions_count}</strong></span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <Text className="mb-0"  >{t.exam.modules}</Text>
                                <span><strong>{examData?.modules_count}</strong></span>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><img src={score_img} alt="" className="me-2"/>{t.exam.scoring}</Accordion.Header>
                        <Accordion.Body>
                            {examData?.modules?.map((m,i)=>{
                                return(
                                    <div className="mb-2    " key={i}>
                                        <span>{m.module_name}</span>
                                        <div className="d-flex justify-content-around">
                                            <div><img src={correct_img}  alt="" /><strong>{m.correct_answer_score}</strong> pt</div>
                                            <div><img src={missing_img}  alt="" /><strong>{m.omitted_answer_score}</strong> pt</div>
                                            <div><img src={wrong_img}  alt="" /><strong>{m.wrong_answer_score}</strong> pt</div>
                                        </div>
                                    </div> 
                                )
                            })}
                        
                        </Accordion.Body>
                    </Accordion.Item>
                    </Accordion>
                </div>
                {
                    examData?.start_date && new Date(examData?.start_date) <= new Date() ?
                        <div>
                            { examData?.time ?
                                <Button className="mx-auto" onClick={startLinearExam}>{examStarted? t.exam.continue_exam :  t.exam.start_exam}</Button>
                            : <Button className="mx-auto" onClick={startModuleExam}>{examStarted? t.exam.continue_exam : t.exam.start_firts_module}</Button>}
                        </div>
                    :
                        <span style={{fontSize:"12px",fontWeight:"500",color:"#6D6F73"}} className="text-center">{t.exams.available_from} {new Date(examData?.start_date).toLocaleDateString()}</span>
                }
                <ConfirmModal props={confirmModal}/>
            </>
            : null}
        </section>
    )
}